import crumbs from 'crumbsjs';

const vacanciesFinder = document.querySelector('.vacancies-popup');
const closeButton = document.querySelector('.vacancies-popup-close');

window.addEventListener('load', (e) => {

  if (document.body.contains(vacanciesFinder)) {
    if (!crumbs.get('hasClosedVacanciesFinder')) {
      if (!crumbs.ls.get('hasClosedVacanciesFinder')) {
        loadInPopup();
      } else {
        deletePopup();
      }
    } else {
      deletePopup();
    }
  }
});

if (document.body.contains(closeButton)) {
  closeButton.addEventListener('click', (e) => {
    crumbs.set('hasClosedVacanciesFinder', true, {type: "minute", value: 30, domain: 'path=/' });
    crumbs.ls.set('hasClosedVacanciesFinder', true, {type: "minute", value: 30, domain: 'path=/' });
    deletePopup();
  });
}

function loadInPopup() {
  setTimeout(() => {
  vacanciesFinder.style.transform = 'translateX(0)';
  }, 3500)
}

function deletePopup() {
  vacanciesFinder.style.transform = 'translateX(400px)';
  setTimeout( () => {
    vacanciesFinder.remove();
  }, 1000)
}