/**
 * Helpers
 */
import {  Modal } from 'bootstrap';
import { onVisibilityChange } from './helpers'

global.jQuery = require('jquery');
window.jQuery = require('jquery');
window.acf = {};
global.acf = {};

/**
 * Web standards
 */
import './lazyload'
import './cookieconsent'
import './langswitcher'
import './vacancies_finder'
import './menu'

/**
 * Scripts which doesn't need dynamic import
 */

/**
 * Dynamic import scripts - based on on page
 */
const jobAlert = document.querySelector('#jobalert');
if (jobAlert) {
  const jobAlertHandler = import('./jobalert');
}

/**
 * Dynamic import scripts - based on in viewport
 */
const elements = [
  '#header_video',
  '.branches__swiper',
  '.settlements__swiper',
  '.latest-vacancies__swiper',
  '.vacancies__header__filter',
  '.single-workers_vacancies',
  '.single-flex_vacancies',
  '#faq',
  '#posts'
];

[].forEach.call(elements, (element) => {


  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

      // Example library without init:
      if (element === '#header_video') {
        const HeaderVideo = import('./front-page-video')
      }

      if (element === '.branches__swiper') {
        const BranchesSwiper = import('./branches')
      }

      if (element === '.settlements__swiper') {
        const SettlementsSwiper = import('./settlements')
      }

      if (element === '.latest-vacancies__swiper') {
        const LatestVacancies = import('./latest-vacancies')
      }

      if (element === '.vacancies__header__filter') {
        const VacanciesHeaderFilter = import('./vacancies_head_filter')
      }

      if (element === '.single-workers_vacancies' || element === '.single-flex_vacancies') {
        const Vacancies = import('./vacancies')
      }

      if (element === '#faq') {
        const Vacancies = import( './faq' )
      }
      
      if (element === '#posts') {
        const Vacancies = import( './posts' )
      }

      // Example library with init:
      // if (element === '[data-aos]' || element === '.aos-element') {
      //   const AOS = import('aos').then(AOS => {
      //     AOS.init();
      //   });
      // }
    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }
  }
});
